import { AgoraFetch } from "./fetch";

// const url = "https://online.doyee.com/api/v1";
const url = "https://dev.online.doyee.com/api/v1";
// const url = "http://192.168.0.222:8080/api/v1";

export const ErpApi = {
    async doLogin(username:string,password:string,userType:string) {
        let response = await AgoraFetch(`${url}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                password: password,
                userType: userType
            })
        });
        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },

    async doOtherLogin(roomName:string,phone:string,username:string) {
        let response = await AgoraFetch(`${url}/online/student/invite_room`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: username,
                phone: phone,
                code: roomName
            })
        });
        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },

    async getClassList(searchData: any,urlName: string) {
        let response = await AgoraFetch(`${url}/online/teacher/list${urlName}?date=${searchData.date}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },
    async classBegin(id: number) {
        let response = await AgoraFetch(`${url}/online/teacher/class/begin?id=${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },
    async getStudentClassList(searchData: any,urlName: string) {
        let response = await AgoraFetch(`${url}/online/student/ban_list${urlName}?date=${searchData.date}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },
    async checkBanRoom(id: number) {
        let response = await AgoraFetch(`${url}/online/student/ban_room?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },
    async getStudentName() {
        let response = await AgoraFetch(`${url}/online/student/student_name`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },
    async saveVideo(banId: number,urlName: string) {
        let response = await AgoraFetch(`${url}/online/teacher/playback/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                banId: banId,
                url: urlName
            })
        });

        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },
    async doLogout() {
        let response = await AgoraFetch(`${url}/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },

    async saveFile(data: any,courseWareType: string) {
        let teacherUrl = "/";
        if(courseWareType == "1"){
            teacherUrl = "/teacher/";
        }
        let response = await AgoraFetch(`${url}/courseware/online${teacherUrl}add `, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },

    async getTeacherFolderList(page: number,size: number) {
        let response = await AgoraFetch(`${url}/courseware/online/teacher/folder/list?page=${page}&size=${size}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });

        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },
    async getTeacherFolderInfo(page: number,size: number,folderId: number) {
        let response = await AgoraFetch(`${url}/courseware/online/teacher/list?page=${page}&size=${size}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                folderId: folderId
            })
        });

        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },

    async getToken() {
        let response = await AgoraFetch(`${url}/online/device/ys/token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },
    async getVideoUrl(token: string,deviceNumber: string) {
        let response = await AgoraFetch(`https://open.ys7.com/api/lapp/live/address/get`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: "accessToken="+token+"&source="+deviceNumber+":1",
        });

        let json = await response.json();
        if (json.status === 0) {
            return json;
        } else {
            return json;
        }
    },
};